import React, { useState, useEffect } from "react"
import axios from "axios"

import Table from "../../../components/table/Table"
import TableRow from "../../../components/table/TableRow"
import TableColumn from "../../../components/table/TableColumn"

import AdminNavBar from "../../../components/AdminNavBar";
import { clear } from "@testing-library/user-event/dist/clear"
import { resolvePath } from "react-router-dom"

const ServiceRequests = () => {

    const [serviceRequests, setServiceRequests] = useState([]);
    const [ticketIdText, setTicketIdText] = useState("");
    const [categoryText, setCategoryText] = useState("");
    const [priorityText, setPriorityText] = useState("");
    const [statusText, setStatusText] = useState("");
    const [createdFromText, setCreatedFromText] = useState("");
    const [createdToText, setCreatedToText] = useState("");
    const [modifiedFromText, setModifiedFromText] = useState("");
    const [modifiedToText, setModifiedToText] = useState("");

    function getServiceRequests() {


        axios.get(process.env.REACT_APP_BASEAPIURL + "api/ServiceRequests", {
            params: {
                ticketId: ticketIdText,
                category: categoryText,
                priority: priorityText,
                status: statusText,
                createdDateFrom: createdFromText,
                createdDateTo: createdToText,
                modifiedDateFrom: modifiedFromText,
                modifiedDateTo: modifiedToText,
            }
        })
        .then((response) => {
            console.log(response)
            setServiceRequests(response.data)
        }).catch((response) => {
            console.log(response);
            alert("Couldn't Load Table");
         })
    }

    const ticketIdTextOnChange = (e) => {
        setTicketIdText(e.target.value)
    }

    const categoryTextOnChange = (e) => {
        setCategoryText(e.target.value)
    }

    const priorityTextOnChange = (e) => {
        setPriorityText(e.target.value)
    }

    const statusTextOnChange = (e) => {
        setStatusText(e.target.value)
    }

    const createdFromTextOnChange = (e) => {
        setCreatedFromText(e.target.value)
    }
    
    const createdToTextOnChange = (e) => {
        setCreatedToText(e.target.value)
    }

    const modifiedFromTextOnChange = (e) => {
        setModifiedFromText(e.target.value)
    }
    
    const modifiedToTextOnChange = (e) => {
        setModifiedToText(e.target.value)
    }

    const emptyFields = (e) => {
        setTicketIdText("");
        setCategoryText("");
        setPriorityText("");
        setStatusText("");    
        setCreatedFromText("")
        setCreatedToText("")
        setModifiedFromText("")
        setModifiedToText("")

        document.getElementById("created-from").value = "";
        document.getElementById("created-to").value = "";

        document.getElementById("modified-from").value = "";
        document.getElementById("modified-to").value = "";

    }

    useEffect(() => {
        getServiceRequests();
     }, [])

    return(


        
        <div>
            <AdminNavBar />

            <h1>Service Requests</h1>

            <div className="mb-1">

                <div>

                    <textarea name="" id="" cols="40" rows="1" className="mr-10" placeholder="Enter Ticket ID" onChange={ticketIdTextOnChange} value={ticketIdText} required></textarea>

                    Category: <select id="category" default-value="" className="ml-12 mr-10" placeholder="Category" onChange={categoryTextOnChange} value={categoryText}>
                        <option value="">All</option>
                        <option value="Bug">Bug</option>
                        <option value="Feedback">Feedback</option>
                        <option value="Support">Support</option>
                    </select>

                    Priority: <select id="priority" default-value="" className="ml-12 mr-10" placeholder="Priority" onChange={priorityTextOnChange} value={priorityText}>
                        <option value="">All</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>

                    Status: <select id="status" default-value="" className="ml-12" placeholder="Status" onChange={statusTextOnChange} value={statusText}>
                        <option value="">All</option>
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <option value="In Progress">In Progress</option>
                    </select>
                </div>


                <div>
                    Created From: <input type="date" id="created-from" onChange={createdFromTextOnChange} className="mr-5"/>
                    Created To: <input type="date" id="created-to" onChange={createdToTextOnChange} className="mr-10"/>

                    Modified From: <input type="date" id="modified-from" onChange={modifiedFromTextOnChange} className="mr-5"/>
                    Modified To: <input type="date" id="modified-to" onChange={modifiedToTextOnChange}/>
                </div>

                <div>
                    
                </div>

                <div className="flex items-center justify-center">
                    <button onClick={getServiceRequests} className="flex items-center justify-center bg-yellow-400 rounded-md h-9 w-2/5 text-white mr-2 mt-5 hover:bg-yellow-500 cursor-pointer">Apply Filters</button>
                    <button onClick={emptyFields} className="flex items-center justify-center bg-yellow-400 rounded-md h-9 w-2/5 text-white mr-2 mt-5 hover:bg-yellow-500 cursor-pointer">Clear Filters</button>
                </div>
            </div>

            <div className="flex flex-col justify-center w-full container mx-auto">

                <Table columnHeaders={["Ticket ID", "Category", "Priority", "Page", "Status"]}>
                    {
                        serviceRequests.map((o, index) => {
                            return(
                                <TableRow key={o.ticketId}>
                                    <TableColumn data={o.ticketId} />
                                    <TableColumn data={o.category} />
                                    <TableColumn data={o.priority} />
                                    <TableColumn data={o.page} />
                                    <TableColumn data={o.status} />
                                </TableRow>
                            )
                        })
            
                    }
                </Table>
            </div>
        </div>
    );

}

export default ServiceRequests;