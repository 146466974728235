import React from 'react';
import { Link } from "react-router-dom";
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './views/Home'
import MenuCreate from './views/Menu/MenuCreate';
import AdminDashboard from './views/Admin/Dashboard';
import Manage from './views/Admin/DataManagement/Manage';
import CuisineTypes from './views/Admin/DataManagement/CuisineTypes';
import CuisineCategories from './views/Admin/DataManagement/CuisineCategories';
import ServiceRequests from './views/Admin/Dashboard/ServiceRequests';
import './styles/App.css';

function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu/create" element={<MenuCreate />} />
          {/* <Route path="/admin/dashboard/login" element={}/> */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/manage" element={<Manage />} />
          <Route path="/admin/manage/cuisinetypes" element={<CuisineTypes />} />
          <Route path="/admin/manage/cuisinecategories" element={<CuisineCategories />} />
          <Route path="/admin/dashboard/service-requests" element={<ServiceRequests />} />

        </Routes>
      </div>
    </div>
  );
}

export default App;