import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import "../styles/components/navbarstyles.css"

const navigation = [
   { name: 'Dashboard', href: '/admin/dashboard', current: true },
   { name: 'Manage App Data', href: '/admin/manage', current: false }
]

const classNames = (...classes) => {
     return classes.filter(Boolean).join(' ')
}


const AdminNavBar = () => {

     const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();

     return (
          <Disclosure as="nav" className="bg-yellow-400">
               {({ open }) => (
                    <>
                         <div className=" mx-auto px-2">
                              <div className="relative flex items-center h-14">
                                   <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        {
                                             open ? (
                                                  <XIcon className="block h-7 w-7" aria-hidden="true" />
                                             ) : (
                                                  <MenuIcon className="block h-7 w-7" aria-hidden="true" />
                                             )
                                        }
                                   </Disclosure.Button>

                                   <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                        <div className="flex-shrink-0 flex items-start">
                                             <Link to="/" className="text-white text-4xl font-bold -ml-5 sm:ml-3">Dashboard</Link>
                                        </div>
                                   </div>

                                   <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                        {/* Profile dropdown */}
                                        <Menu as="div" className=" relative">
                                             <div>
                                                  
                                                  {isAuthenticated &&

                                                       <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                            <span className="sr-only">Open user menu</span>
                                                            <img
                                                                 className="h-10 w-10 rounded-full"
                                                                 src={user.picture}
                                                                 referrerPolicy="no-referrer"
                                                            />
                                                       </Menu.Button>


                                                  }

                                             </div>
                                             <Transition
                                                  as={Fragment}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95"
                                             >
                                                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                                       <Menu.Item>
                                                            {({ active }) => (
                                                                 <a
                                                                      href="#"
                                                                      className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                 >
                                                                      Your Profile
                                                                 </a>
                                                            )}
                                                       </Menu.Item>
                                                       <Menu.Item>
                                                            {({ active }) => (
                                                                 <a
                                                                      href="#"
                                                                      className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                 >
                                                                      Settings
                                                                 </a>
                                                            )}
                                                       </Menu.Item>
                                                       <Menu.Item>
                                                            {({ active }) => (
                                                                 <a
                                                                      href="#"
                                                                      onClick={() => {logout()}}
                                                                      className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                 >
                                                                      Sign out
                                                                 </a>
                                                            )}
                                                       </Menu.Item>
                                                  </Menu.Items>
                                             </Transition>
                                        </Menu>
                                   </div>
                              </div>
                         </div>

                         <Disclosure.Panel as="div" className="relative">
                              <div className=" absolute top-0 left-0 w-64 shadow-lg py-1 bg-yellow-400 ring-1 ring-black ring-opacity-5 focus:outline-none z-20" id="hamburger-menu">
                                   {navigation.map((item) => (
                                        <Transition
                                             key={item.name}
                                             as={Fragment}
                                             enter="transition ease-out duration-100"
                                             enterFrom="transform opacity-0 scale-95"
                                             enterTo="transform opacity-100 scale-100"
                                             leave="transition ease-in duration-75"
                                             leaveFrom="transform opacity-100 scale-100"
                                             leaveTo="transform opacity-0 scale-95"
                                        >
                                             <Disclosure.Button
                                                  key={item.name}
                                                  as="a"
                                                  href={item.href}
                                                  className={classNames(
                                                       /*item.current ? 'bg-gray-900 text-white' :*/ 'text-white hover:bg-yellow-500 hover:text-white',
                                                       'block px-3 py-5 rounded-md text-base font-medium'
                                                  )}
                                                  aria-current={item.current ? 'page' : undefined}
                                             >
                                                  {item.name}
                                             </Disclosure.Button>
                                        </Transition>
                                   ))}
                              </div>
                         </Disclosure.Panel>

                    </>
               )}
          </Disclosure>
     )
}

export default AdminNavBar;