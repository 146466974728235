import React, {useState, useEffect} from 'react'

const Table = ({columnHeaders, children}) => {
   
   // const [headers, setHeaders] = useState([]);

   // useEffect(() => {
   //    console.log(props)
   //    console.log(columnHeaders);
   //    setHeaders(columnHeaders);
   // }, [])
   

   return (
      <div className="overflow-y-auto" style={{maxHeight: "400px"}}>
         <table className="table-auto rounded-md mt-1 h-full w-full">
            <thead>
               <tr className='bg-gray-600 text-white'>
                  {
                     columnHeaders.map((headerText, index) => {
                        return (
                           index == 0 
                           ? <th className='rounded-tl-md py-4' key={index}>{headerText}</th>
                           : index == columnHeaders.length - 1 
                           ? <th className='rounded-tr-md py-4' key={index}>{headerText}</th>
                           : <th className='py-4' key={index}>{headerText}</th> 
                        )
                     })
                  }
               </tr>
            </thead>
            <tbody>
               {children}
            </tbody>
         </table>
      </div>
   )
}

export default Table;