import React, { useState, useEffect } from "react"
import axios from "axios"
import AdminNavBar from "../../../components/AdminNavBar"
import Table from "../../../components/table/Table"
import TableRow from "../../../components/table/TableRow"
import TableColumn from "../../../components/table/TableColumn"
import Modal from "../../../components/modal/Modal"
import ModalFooter from "../../../components/modal/ModalFooter"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { faPen } from "@fortawesome/free-solid-svg-icons"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import ModalBody from "../../../components/modal/ModalBody"


const CuisineCategories = () => {
   const [cuisineCategories, setcuisineCategories] = useState([]);
   const [cuisineCategory, setCuisineCategory] = useState("");
   const [editedCuisineCategory, setEditedCuisineCategory] = useState("");
   const [cuisineCategoryId, setCuisineCategoryId] = useState(0);
   const [isEditMode, setIsEditMode] = useState(false);

   function isEven(index) {
      let truthy = (index % 2 == 0)
      return truthy
   }

   function getCuisineCategories() {
      axios.get(process.env.REACT_APP_BASEAPIURL + "api/Cuisines/CuisineCategories")
         .then((response) => {
            console.log(response)
            setcuisineCategories(response.data)
         })
   }

   function onChangeCuisineCategory(e) {
      setCuisineCategory(e.target.value)
      
   }

   function onChangeEditCuisineCategory(e){
      setEditedCuisineCategory(e.target.value)
   }

   function onClickAddCuisineCategory() {
      axios.post(process.env.REACT_APP_BASEAPIURL + "api/Cuisines/CuisineCategories", {
         name: cuisineCategory
      }).then(() => {
         getCuisineCategories();
      }).catch(() => {
         alert("Could not add cuisine category at this time. Please try again later.")
      }).finally(() => {
         setCuisineCategory("")
      })
      
   }

   function onClickEditCuisineCategory(id) {
      axios.put(process.env.REACT_APP_BASEAPIURL + "api/CuisineCategories/" + cuisineCategoryId, {
         id: 0,
         name: editedCuisineCategory
      }).then((response) => {
         getCuisineCategories();
      }).catch(() => {
         alert("Could not edit cuisine category. Please try again later.")
      }).finally(() => {
         setEditedCuisineCategory("");
         setIsEditMode(false);
      })
   }

   function onClickDeleteCuisineCategory(id) {
      axios.delete(process.env.REACT_APP_BASEAPIURL + "api/CuisineCategories/" + id)
         .then(() => {
            getCuisineCategories();
         })
         .catch(() => {
            alert("Could not delete cuisine category at this time. Please try again later.")
         })
   }

   useEffect(() => {
      // Load cuisine types 
      getCuisineCategories();
   }, [])

   return (
      <>
         <AdminNavBar />
         {isEditMode
            && <Modal title="Edit Cuisine Category">
               <ModalBody>
                  <input type="text" placeholder="Edit cuisine category" className="py-1 pl-1 my-2 border-2 -ml-2 w-full rounded-md" onChange={onChangeEditCuisineCategory} value={editedCuisineCategory} />
               </ModalBody>
               <ModalFooter>
                  <PrimaryButton text="Submit" onClick={onClickEditCuisineCategory}/>
                  <SecondaryButton text="Cancel" onClick={() => { setIsEditMode(false) }} />
               </ModalFooter>
            </Modal>
         }
         <div className="container mx-auto">
            <h2 className="text-2xl font-medium">Cuisine Categories</h2>
            <div className="flex flex-col justify-center w-full">
               <Table columnHeaders={["Id", "Cuisine Category", "Edit", "Delete"]}>
                  {
                     cuisineCategories.map((o, index) => {
                        return (
                           <TableRow key={o.id}>
                              <TableColumn data={o.id} flipColor={isEven(index)} />
                              <TableColumn data={o.name} flipColor={isEven(index)} />
                              <TableColumn data={<FontAwesomeIcon icon={faPen} className="hover:cursor-pointer" onClick={() => { setCuisineCategoryId(o.id); setEditedCuisineCategory(o.name); setIsEditMode(true); }} />} flipColor={isEven(index)} />
                              <TableColumn data={<FontAwesomeIcon icon={faTrash} className="hover:cursor-pointer" onClick={() => onClickDeleteCuisineCategory(o.id)} />} flipColor={isEven(index)} />
                           </TableRow>
                        )
                     })
                  }
                  <TableRow className="border-t-2 border-slate-300">
                     <TableColumn data={"..."} />
                     <TableColumn data={<input type="text" placeholder="Add new cuisine category" className="py-1 w-4/5 text-center rounded-sm" onChange={onChangeCuisineCategory} value={cuisineCategory} />} />
                     <TableColumn data={<SecondaryButton text="Clear" onClick={() => { setCuisineCategory("") }} />} />
                     <TableColumn data={<PrimaryButton text="Add" onClick={onClickAddCuisineCategory} />} />
                  </TableRow>
               </Table>
            </div>
         </div>
      </>
   )
}

export default CuisineCategories;