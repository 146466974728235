import React from 'react';

const TableColumn = ({data, flipColor}) => {
   return (
      <>
      <td className={"py-2 " + (flipColor ? "bg-gray-50" : "bg-gray-200")}>
         {data}
      </td>
      </>
   )
}

export default TableColumn;