import React from 'react';
// import '../styles/Home.css';
import NavBar from "../components/NavBar";


const Home = () => {
   return (
      <>
      <NavBar />
      <div className="text-black font-sans" id="entirety">
         <div className="outline-width: 1px" id="header">
            <h1 className="text-6xl"id="restaurant-name">Joe's Pizza</h1>
            <h2 className="text-6xl m-10" id="address">185 Changebridge Rd, Montville, NJ</h2>
            <div className="flex justify-between pb-10">
               <p className="text-3xl" id="user-visits">User Visits: 48</p>
               <p className="text-3xl" id="top-choice">Top Choice: Eggplant Parmesan</p>
               <p className="text-3xl" id="choices-made">Choices made: 34</p>
            </div>
            
         </div>
         <div className="flex justify-between">
         <div className="text-3xl w-1/2" id="interaction">
            <a to="/">
               <button className="bg-transparent hover:bg-slate-900 text-slate-900 font-semibold hover:text-white py-2 px-4 border border-slate-900 hover:border-transparent rounded" id="menu">Add to your menu +</button>
            </a>
            <br></br>
            <a to="/">
               <button className="bg-transparent hover:bg-slate-900 text-slate-900 font-semibold hover:text-white py-2 px-4 border border-slate-900 hover:border-transparent rounded m-10" id="referral">See how other restaurants have benefited from our help!</button>
            </a>
         </div>
         <div className="text-3xl w-1/2" id="analytics">
            <h2 className="text-5xl m-5"id="header-analytics">Your Analytics</h2>
            <p className="m-10"id="graphs">*Graphs/Visuals here*</p>
            <p className="m-10" id="numbers">Percentages, numbers here</p>
         </div>
         </div>
         
      </div>
      </>
   )
}

export default Home;