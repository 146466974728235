import React, { useState } from "react";

const MenuCreate = () => {
   const [menuSections, setMenuSections] = useState(["Appetizers", "Entrees", "A La Carte", "Sides", "Desserts", "Drinks", "Alcoholic Beverages"]);
   const [menuSubSections, setMenuSubSections] = useState([{}]); // Ex: { menuSection: "Entrees", subSection: "Sandwiches" }
   const [menuItems, setMenuItems] = useState([{}]) // { section, name, image, ingredients, allergens, description }

   const [menuSection, setMenuSection] = useState("")
   const [foodItemName, setFoodItemName] = useState("")
   const [foodItemImage, setFoodItemImage] = useState("https://www.kahnscatering.com/wp-content/uploads/2013/12/kahns-catering-food-entree-9-jessicastrickland-600x400.jpg")
   const [mainIngredients, setMainIngredients] = useState("")
   const [allergens, setAllergens] = useState("")
   const [description, setDescription] = useState("")

   const [testArray, setTestArray] = useState([1, 2, 3, 4, 5]);


   const menuSectionOnChange = (e) => {
      setMenuSection(e.target.value)
   }
   const foodItemNameOnChange = (e) => {
      setFoodItemName(e.target.value)
   }
   const mainIngredientsOnChange = (e) => {
      setMainIngredients(e.target.value)
   }
   const allergensOnChange = (e) => {
      setAllergens(e.target.value)
   }
   const descriptionOnChange = (e) => {
      setDescription(e.target.value)
   }

   const addToMenuOnClick = (e) => {
      const menuItem = {
         section: menuSection,
         name: foodItemName,
         image: foodItemImage,
         ingredients: mainIngredients,
         allergens,
         description
      }
      setMenuItems([...menuItems].map(item => {
         return item;
      }))
   }

   return (
      <div>
         <h1 className="text-5xl font-bold mb-5">Create Menu</h1>
         <div className=" flex flex-col w-2/6 mx-auto justify-center" id="menu-item-entry">
            <div className="flex justify-around mb-5">
               <b>Select Restaurant</b>
               <select className="border-4 rounded-lg w-1/2" name="restaurantName" required>
                  <option> Test Restaurant </option>
               </select>
            </div>
            <hr />
            <h2 className="text-3xl font-bold mt-2 mb-5">Create Menu Item</h2>
            <div className="flex justify-between w-full mb-2">
               <b>Menu Section</b>
               <select className="border-4 rounded-lg w-3/5" onChange={menuSectionOnChange}>
                  <option></option>
                  {
                     menuSections.map((value, index) => {
                        return (
                           <option value={value} key={index}>
                              {value}
                           </option>
                        )
                     })
                  }
               </select>
            </div>
            <div className="flex justify-between w-full mb-2">
               <b>Food Item Name</b>
               <input type="text" className="border-2 rounded-lg w-3/5" onChange={foodItemNameOnChange} placeholder="Mom's Famous Spaghetti" />
            </div>
            <div className="flex justify-between w-full mb-2">
               <b>Main Ingredients</b>
               <textarea rows={3} className="border-2 rounded-lg w-3/5" onChange={mainIngredientsOnChange} placeholder="Basil, Tomatoes, Onions, Garlic...">
               </textarea>
            </div>
            <div className="flex justify-between w-full mb-2">
               <b>Allergens</b>
               <input type="text" className="border-2 rounded-lg w-3/5" onChange={allergensOnChange} placeholder="Milk, wheat" />
            </div>
            <div className="flex justify-between items-center w-full mb-2">
               <b>Description</b>
               <textarea rows={3} className="border-2 rounded-lg w-3/5" onChange={descriptionOnChange} placeholder="Spaghetti crafted from scratch. Just like mom used to make.">
               </textarea>
            </div>
         </div>
         <div>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={addToMenuOnClick}>
               Add to Menu
            </button>
         </div>
         <div className="w-10/12 mx-auto mt-5" id="menu">
            <h2 className="text-4xl font-bold my-4">Menu Preview</h2>
            <hr />

            <div className="w-full text-left" id="appetizer-section">
               <h3 className="text-3xl font-bold">Appetizers</h3>
               <div className="flex flex-wrap gap-x-2 gap-y-5 w-full mt-3" id="appetizer-items">
                  {
                     testArray.map((value, index) => {
                        return (
                           <div className="max-w-xs mx-7 rounded overflow-hidden shadow-lg m-0" key={index}>
                              <img className="w-full" src="https://www.kahnscatering.com/wp-content/uploads/2013/12/kahns-catering-food-entree-9-jessicastrickland-600x400.jpg" alt="Sunset in the mountains" />
                              <div className="px-6 py-3">
                                 <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
                                 <p className="text-gray-700 text-base">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                                 </p>
                              </div>
                              <div className="px-6 pt-2 pb-2">
                                 <p className="font-bold mb-2">Allergens</p>
                                 <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">photography</span>
                                 <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">travel</span>
                                 <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">winter</span>
                                 <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">winter</span>
                                 <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">winter</span>
                              </div>
                           </div>
                        )
                     })
                  }
               </div>
            </div>
            <div className="w-full text-left" id="aLaCarte-section">
               <h3 className="text-3xl font-bold">A La Carte</h3>
            </div>
            <div className="w-full text-left" id="entrees-section">
               <h3 className="text-3xl font-bold">Entrees</h3>
            </div>
            <div className="w-full text-left" id="sides-section">
               <h3 className="text-3xl font-bold">Sides</h3>
            </div>
            <div className="w-full text-left" id="desserts-section">
               <h3 className="text-3xl font-bold">Desserts</h3>
            </div>
            <div className="w-full text-left" id="drinks-section">
               <h3 className="text-3xl font-bold">Drinks</h3>
            </div>
            <div className="w-full text-left" id="alcoholicBeverages-section">
               <h3 className="text-3xl font-bold">Alcoholic Beverages</h3>
            </div>
         </div>
      </div>

   )
}

export default MenuCreate;