import React, { useState, useEffect } from "react"
import axios from "axios"
import AdminNavBar from "../../../components/AdminNavBar"
import Table from "../../../components/table/Table"
import TableRow from "../../../components/table/TableRow"
import TableColumn from "../../../components/table/TableColumn"
import Modal from "../../../components/modal/Modal"
import ModalFooter from "../../../components/modal/ModalFooter"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { faPen } from "@fortawesome/free-solid-svg-icons"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import ModalBody from "../../../components/modal/ModalBody"


const CuisineTypes = () => {
   const [cuisineTypes, setCuisineTypes] = useState([]);
   const [cuisineType, setCuisineType] = useState("");
   const [editedCuisineType, setEditedCuisineType] = useState("");
   const [cuisineTypeId, setCuisineTypeId] = useState(0);
   const [isEditMode, setIsEditMode] = useState(false);

   function isEven(index) {
      let truthy = (index % 2 == 0)
      return truthy
   }

   function getCuisineTypes() {
      axios.get(process.env.REACT_APP_BASEAPIURL + "api/Cuisines/CuisineTypes")
         .then((response) => {
            console.log(response)
            setCuisineTypes(response.data)
         })
   }

   function onChangeCuisineType(e) {
      setCuisineType(e.target.value)
   }

   function onChangeEditCuisineType(e){
      setEditedCuisineType(e.target.value)
   }

   function onClickAddCuisineType() {
      axios.post(process.env.REACT_APP_BASEAPIURL + "api/Cuisines/CuisineTypes", {
         name: cuisineType
      }).then(() => {
         getCuisineTypes();
      }).catch(() => {
         alert("Could not add cuisine type at this time. Please try again later.")
      }).finally(() => {
         setCuisineType("");
      })
      
   }

   function onClickEditCuisineType() {
      axios.put(process.env.REACT_APP_BASEAPIURL + "api/CuisineTypes/" + cuisineTypeId, {
         id: 0,
         name: editedCuisineType
      }).then((response) => {
         getCuisineTypes();
      }).catch(() => {
         alert("Could not edit cuisine type. Please try again later.")
      }).finally(() => {
         setIsEditMode(false);
      })
   }

   function onClickDeleteCuisineType(id) {
      axios.delete(process.env.REACT_APP_BASEAPIURL + "api/CuisineTypes/" + id)
         .then(() => {
            getCuisineTypes();
         })
         .catch(() => {
            alert("Could not delete cuisine type at this time. Please try again later.")
         })
   }

   useEffect(() => {
      // Load cuisine types 
      getCuisineTypes();
   }, [])

   return (
      <>
         <AdminNavBar />
         {isEditMode
            && <Modal title="Edit Cuisine Type">
               <ModalBody>
                  <input type="text" placeholder="Add new cuisine type" className="py-1 pl-1 my-2 border-2 -ml-2 w-full rounded-md" onChange={onChangeEditCuisineType} value={editedCuisineType} />
               </ModalBody>
               <ModalFooter>
                  <PrimaryButton text="Submit" onClick={onClickEditCuisineType} />
                  <SecondaryButton text="Cancel" onClick={() => setIsEditMode(false)} />
               </ModalFooter>
            </Modal>
         }
         <div className="container mx-auto">
            <h2 className="text-4xl font-medium my-4">Cuisine Types</h2>
            <div className="flex flex-col justify-center w-full">
               <Table columnHeaders={["Id", "Cuisine Type", "Edit", "Delete"]}>
                  {
                     cuisineTypes.map((o, index) => {
                        return (
                           <TableRow key={o.id}>
                              <TableColumn data={o.id} flipColor={isEven(index)} />
                              <TableColumn data={o.name} flipColor={isEven(index)} />
                              <TableColumn data={<FontAwesomeIcon icon={faPen} className="hover:cursor-pointer" onClick={() => { setCuisineTypeId(o.id); setIsEditMode(true); setEditedCuisineType(o.name); }} />} flipColor={isEven(index)} />
                              <TableColumn data={<FontAwesomeIcon icon={faTrash} className="hover:cursor-pointer" onClick={() => onClickDeleteCuisineType(o.id)} />} flipColor={isEven(index)} />
                           </TableRow>
                        )
                     })
                  }
               </Table>
               <div className="grid grid-cols-12 border-t-slate-300 border-t-2 bg-gray-200 py-1">
                  <div className="-ml-3">
                     <p className="">...</p>
                  </div>
                  <div className=" col-span-7">
                     <input type="text" placeholder="Add new cuisine type" className="py-1 w-4/5 text-center rounded-md" onChange={onChangeCuisineType} value={cuisineType} />
                  </div>
                  <div className="col-span-2">
                     <SecondaryButton text="Clear" onClick={() => { setCuisineType("") }} />
                  </div>
                  <div className="col-span-2 -ml-10">
                     <PrimaryButton text="Add" onClick={onClickAddCuisineType} />
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default CuisineTypes;