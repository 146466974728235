import React from 'react';

const PrimaryButton = ({text, onClick}) => {
   return (
      <>
      <button type="button" 
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-slate-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClick}>
         {text}
      </button>
      </>
   )
}

export default PrimaryButton;