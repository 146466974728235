import React from "react"
import AdminNavBar from "../../components/AdminNavBar";
import { Link }  from "react-router-dom";


const AdminDashboard = () => {
   return (
      <>
         <AdminNavBar />
         <p className="text-black">This is the admin dashboard overview</p>
         
         
         <div className="mb-1 flex items-center justify-center bg-yellow-500 rounded-md h-7 w-2/5 text-white mr-2 mt-1 hover:bg-yellow-600">
            <Link to="/admin/dashboard/service-requests"><button>Service Requests</button></Link>
         </div>

      </>
   )
}

export default AdminDashboard;