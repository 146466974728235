import React from 'react';

const ModalBody = ({children}) => {
   return (
      <div>
         {children}
      </div>
   )
}

export default ModalBody;