import React from 'react'
import AdminNavBar from '../../../components/AdminNavBar'

const Manage = () => {
   return (
      <>
         <AdminNavBar />
         <h2 className="text-4xl">Navigation Links</h2>
         <a href="/admin/manage/cuisinetypes" className='text-blue-800 font-bold'>Cuisine Types</a>
         <br />
         <a href="/admin/manage/cuisinecategories" className='text-blue-800 font-bold'>Cuisine Categories</a>
      </>
   )
}

export default Manage;