import React from 'react';

const Modal = ({ title, children }) => {
   return (
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
         <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
         <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
               <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white pt-5">
                     <div>
                        <div className="mt-3 sm:ml-4 text-center sm:mt-0 sm:text-left">
                           <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">{title}</h3>
                           <div className="mt-2">
                              {children}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Modal;